.Screen3D {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  perspective: 600px;
  /* pointer-events: none; */
}

.Screen3D > * { pointer-events: auto; }

.Screen3D > *:nth-child(1) { transform: translateZ(-40px) rotateY(30deg); }
.Screen3D > *:nth-child(2) { transform: translateZ(-100px) rotateY(20deg); }
.Screen3D > *:nth-child(3) { transform: translateZ(-130px) rotateY(5deg); }
.Screen3D > *:nth-child(4) { transform: translateZ(-130px) rotateY(-5deg); }
.Screen3D > *:nth-child(5) { transform: translateZ(-100px) rotateY(-20deg); }
.Screen3D > *:nth-child(6) { transform: translateZ(-40px) rotateY(-30deg); }


.new-usecase {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 80px;
  align-items: center;

  border-radius: 60px 0;
  width: 210px;
  cursor: pointer;
}
.new-usecase:hover {
  background: linear-gradient(to bottom,rgba(67, 255, 255, 0.308) 0%,rgba(67, 255, 255, 0.1) 35%,rgba(67, 255, 255, 0.1) 65%,rgba(67, 255, 255, 0.308) 100%);
}

.new-usecase .new-usecase-icon {
  cursor: pointer;
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: url('../../../../images/adusecase.png') rgba(54, 209, 248, 0.6) no-repeat center center;
  outline: none;
  margin-bottom: 10px;
}
.new-usecase:hover .new-usecase-icon{
  background-color: rgb(54, 209, 248);
  box-shadow: 0 0 15px rgba(54, 209, 248, 0.747);
}
.new-usecase .new-usecase-text {
  color: #43ffff;
  font-size: 30px;
  white-space: nowrap;
}


.phases-titles {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  font-size: 20px;
}

.phases-titles .phase-title { white-space: nowrap; }
.phases-titles .phase-title.inactive{ opacity: 0.3; }



.capability-line {
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  bottom: 130px;
  height: 3px;
  background: rgb(245, 67, 67);
  box-shadow: 0 0 15px 4px rgba(245, 67, 67, 0.4);

}

.capability-btn {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateX(0px) translateY(-50%);
  background-image: url("../../../../images/curved_capability.png");
}

.capability-btn .notif{
  position: absolute;
  bottom: 0;
  left: 0;
}

.Screen3D.nb-1 .phases-titles,
.Screen3D.nb-2 .phases-titles { left: 450px; top: 14%; bottom: 31%; }
.Screen3D.nb-3 .phases-titles { left: 650px; top: 13.5%; bottom: 30%; }
.Screen3D.nb-4 .phases-titles { left: 860px; top: 12%; bottom: 29.5%; }
.Screen3D.nb-5 .phases-titles { left: 1050px; top: 12%; bottom: 29.5%; }
.Screen3D.nb-6 .phases-titles { display: none; }



.screen.capability-2 .capability-line { bottom: 255px; }
.screen.capability-3 .capability-line { bottom: 310px; }
.screen.capability-4 .capability-line { display: none; }

.Screen3D .capability-line {
  transform: translateY(-50%);
  left: 10px;
}
.Screen3D.nb-1 .capability-line { width: 640px; }
.Screen3D.nb-2 .capability-line { width: 640px; }
.Screen3D.nb-3 .capability-line { width: 820px;}
.Screen3D.nb-4 .capability-line { width: 990px;}
.Screen3D.nb-5 .capability-line { width: 1210px;}
.Screen3D.nb-6 .capability-line { width: 1210px;}
