#home {
  width: 100%;
  height: 100%;
  background: url('../../images/home/bg-front.png') no-repeat center center;
  background-size: cover;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(27, 48, 107, 0.85);
  background: transparent;
}

.overlay.transparent {
  background: transparent;
}


.dialog-enter { opacity: 0.01; }
.dialog-enter-active { opacity: 1; transition: all 300ms; }
.dialog-exit { opacity: 1; }
.dialog-exit-active {
  pointer-events: none;
  opacity: 0.01;
  transition: all 300ms;
}

.home-leftcol,
.home-rightcol {

  position: absolute;
  left: 0;
  width: 350px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 15px 0;
}


.home-leftcol .human-resources{
  position: absolute;
  top: 250px;
  width: 250px;
}
.home-leftcol .ebit{
  position: absolute;
  bottom: 15px;

}


.home-rightcol {
  right: 0;
  left: auto;
  width: 300px;
  justify-content: space-between;
  /* border: 1px solid  red; */
}




.debug-btns {
  position: absolute;
  top: 0;
  padding: 20px;
  z-index: 3000;
  /* background: lime; */
}
.debug-btns button{
  display: block;
}
