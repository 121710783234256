:root {
  --lightblue: #45fdff;
  --disabled: #1B5B87;
  --warning: #ff6e42;
  --error: #df3838;
  --text-dark: #2687B1;
  --green: rgb(19, 255, 157);
  --overlay: rgba(0, 224, 253, 0.8);
  --opaque-overlay: rgba(15, 51, 75, 0.95);

}


*, *:before, *:after { box-sizing: border-box; }

html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: fixed;


  overflow: hidden;
  user-select: none;
  background: #000;
  color: var(--lightblue);

  background: url('images/home/bg-back.png') no-repeat center center;
  background-size: cover;

}

#app {

  width: 1920px;
  height: 1080px;



  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%)  translateY(-50%);


  font-family: "mohaveregular", arial;
  transform-origin: top left;

}

img {
  max-width: 100%;
  max-height: 100%;
}

button {
  font-size: 1em;
  font-family: inherit;
}

#app * {
  scrollbar-width: thin;
  scrollbar-color: var(--lightblue) #1a1941;
}

#app ::-webkit-scrollbar {
  width: 5px;
}

#app ::-webkit-scrollbar-track {
  background: #1a1941;
  overflow: visible;
}

#app ::-webkit-scrollbar-thumb {
  background: var(--lightblue);
  border-radius: 10px;
}

#app ::-webkit-scrollbar-thumb:hover {
  background: #44a2a3;

}

/* for all popups !*/
.popup {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 330px;
  right: 310px;

  background: linear-gradient(to bottom, rgba(20, 49, 88, 0.97), rgba(24, 71, 113, 0.97));
}


.close {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 33px;
  height: 33px;
  background: url("./images/close.png");
  cursor: pointer;
}

.close:hover {
  filter: drop-shadow(0 0 10px rgba(69, 252, 255, 0.678))
}


.gradientTest {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: radial-gradient(circle at 10% 50%, transparent, transparent 50px, red 50px, red 100%);
  opacity: 0.2;
  /* pointer-events: none; */
}
