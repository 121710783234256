.KpiProgressbar-ctnr  { position: relative; }

.KpiProgressbar {
  position: relative;
  height: 7px;
  margin-top: 20px;
  background: rgba(42, 158, 197, 0.5);
  border-radius: 0 0 10px 10px;
  overflow: hidden;

}




.on-penalty .KpiProgressbar {
  background: rgba(255, 110, 66, 0.5);
}
.on-penalty .KpiProgress {
  background: var(--warning);
}
.on-reward .KpiProgressbar {
  background: rgba(19, 255, 157, 0.5);
}
.on-reward .KpiProgress {
  background: var(--green);
}


.KpiProgress {
  background: #46feff;
  height: 100%;
  border-radius: 0 10px 0 0;
  /* box-shadow: inset 0 0 0 1px rgba(42, 158, 197, 0.5); */
  /* transition: width 0.6s; */
}

.goal {
  position: absolute;
  height: 8px;
  bottom: 0;
  border-left: 1px solid  rgba(42, 158, 197, 1);
}

.goal .goal-icon-low,
.goal .goal-star {
  position: absolute;
  top: 0;
  width: 16px;
  height: 16px;
  /* border: 1px solid  red; */
  transform: translateX(-50%)  translateY(-110%);
  background: url("../../../images/star.png");
  background-size: contain;
}

.goal .goal-icon-low {
  background-image: url("../../../images/sens_interdit.png");

}

.goal .goal-star.filled {
  background-image: url("../../../images/starlight.png");
}
.goal .goal-icon-low.activated {
  background-image: url("../../../images/sens_interdit_activated.png");
}

.kpi-value {
  position: absolute;
  top: 100%;
  margin-top: 5px;
  transform: translateX(-50%) ;
}

/****************************************
BIG VARIATION
****************************************/

.big .KpiProgressbar{
  height: 30px;
  margin-top: 70px;
  border-radius: 0 0 35px 35px;
}

.big .KpiProgress {
  border-radius: 0 35px 0 0;
  /* box-shadow: inset 0 0 0 2px rgba(42, 158, 197, 0.5); */
}

.big .kpi-value { font-size: 30px; }

.big .goal { height: 30px; }

.big .goal .goal-icon-low,
.big .goal .goal-star {
  width: 45px;
  height: 45px;
}