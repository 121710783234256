.value{
  color: var(--lightblue);
  max-width: 50%;
  font-size: 35px;
  text-align: center;
}
.value .value-num{
  display: inline-block;
  position: relative;
  font-size: 1em;
  line-height: 1em;
}

.value.halo .value-num::before {
  content: "";
  position: absolute;
  bottom: 0;

  transform: translateY(-200%);
  left: -20%;
  right: -20%;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0 20px 15px rgba(69, 252, 255, 0.377);
  z-index: 0;
}

.value .value-unit{
  opacity: 0.7;
  text-transform: uppercase;
  vertical-align: super;
  font-size: 0.5em;
  line-height: 0.5em;
}

.value .value-desc {
  opacity: 0.7;
  text-transform: uppercase;
  font-size: 0.4em;
}


/**********************************
  PROGRESS BAR
**********************************/

.progress {
  position: relative;
  border: 2px solid var(--lightblue);
  width: 220px;

  padding-bottom: 3.2px;
  padding-left: 38.4px;
  padding-right: 38.4px;
  padding-top: 9.6px;

  font-size: 32px;
  line-height: 1em;

  margin: 0 auto;
  border-radius: 99px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}
.progress .progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(69, 252, 255, 0.5);
  color: var(--lightblue);

  transition: width linear;

  /* transition-duration: 0.5s; */
}
.progress .progress-spinner{
  width: 1em;
  height: 1em;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}


.progress.locked { border: 2px solid var(--warning); }
.progress.locked .progress-bar{ background: rgba(255, 153, 69, 0.5); }
.progress.locked .progress-spinner{
  width: 25px;
  height: 25px;
  background: url('../../images/sens_interdit_activated.png');
  background-size: contain;
}
.progress.locked .progress-value{color: var(--warning);}


/**********************************
  SPINNER
**********************************/
.spiner {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 0.15em solid rgba(255, 255, 255, 0.15);
  border-top-color: rgba(69, 252, 255, 1);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s linear infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}



/**********************************
  NOTIF
**********************************/
.notif {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--green) center center no-repeat;
  background-size: 70% auto;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 5px;
  font-size: 25px;
  line-height: 25px;
  color: #fff;
  font-weight: bold;
}

.notif.empty {
  background-image: url('../../images/checkmark.png');
}

.bad.notif {
  background-color: var(--warning);
  background-image: url('../../images/exclamation.png');
  background-size: auto 70% ;
}

/**********************************
  REPLAYABLE
**********************************/

.replayable {
  width: 70px;
  height: 70px;
  border-radius: 50% 50%;
  bottom: 0;
  left: 0;
  background: #FFFFBF no-repeat 70% 30%;
  background-size: 18px;
  transform: translateX(-40%)  translateY(40%);

  font-size: 25px;

  color: #000;

  display: flex;
  justify-content: flex-end;
  align-items: start;
  padding-top: 8px;
  padding-right: 20px;
}
.replayable.infinite {
  background-image: url("../../images/replay.png");

}

.flip-close-btn,
.flip-info-btn {
  position: absolute;
  width: 40px;
  height: 40px;
  background: none;
  border: 1px solid  var(--lightblue);
  color: var(--lightblue);
  opacity: 0.7;
  font-size: 22px;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.flip-close-btn:hover,
.flip-info-btn:hover {
  opacity: 1;
  background: rgba(69, 252, 255, 0.6);
}

.flip-close-btn {
  opacity: 1;
  border: 1px solid  #192249;
  color: #192249;
}
