.PhaseEndPopup-overlay  {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 0, 0, 0.308);
  background: linear-gradient(to bottom, rgba(20, 49, 88, 0.8), rgba(24, 71, 113, 0.8));

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.PhaseEndPopup {
  background: linear-gradient(to top, #143158, #184771);
  border-radius: 30px;
  padding: 100px;
  padding-right: 50px;

  margin: 70px;

  font-size: 30px;

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;


}

.PhaseEndPopup .PhaseEndPopup-content{
  padding-right: 50px;
  overflow-y: auto;
}
.PhaseEndPopup .PhaseEndPopup-button{
  margin-top: 50px;
}

.PhaseEndPopup button{
  display: block;
  border: 1px solid var(--lightblue);
  border-radius: 999px;
  outline: none;
  background: none;
  font-size: 40px;
  text-transform: uppercase;
  padding: 10px 60px;
  margin: 0 auto;
  color: inherit;
  cursor: pointer;
}

.PhaseEndPopup button:hover{
  background: var(--lightblue);
  color: var(--disabled);
}
.PhaseEndPopup button.disabled{
  background: var(--disabled);
  color: var(--text-dark);
  cursor: no-drop;
}