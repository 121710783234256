.uc-activities-popup {
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.uc-activities-popup .top {
  display: flex;
  flex-direction: row;
}

.uc-activities-popup-phase{
  padding: 5px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.uc-activities-popup-phase {
  text-align: center;
  margin-right: 10px;
}

.uc-activities-popup-phase .phase-title{
  text-transform: uppercase;
  color: var(--text-dark);
}
.uc-activities-popup-phase .phase-nb{
  color: var(--lightblue);
  font-size: 80px;
  line-height: 80px;
}


.uc-activities-popup-title-ctnr {
  min-width: 275px;
  width: 275px;
  max-width: 275px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-bottom: 30px;
  padding-top: 40px;

}


.uc-activities-popup.phase_4  .uc-activities-popup-title {
  font-size: 35px;

}
.uc-activities-popup-title {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 45px;

  color: #FFFFFD;
  text-transform: uppercase;

}

.uc-activities-popup-subtitle {
  font-size: 22px;
  white-space: nowrap;
  margin-top: 5px;
}

.uc-activities-popup .categs {
  flex-grow: 1;
  display: flex;
  margin-right: 40px;
  justify-content: center;
}

.uc-activities-popup .instructions {
  font-size: 40px;

  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uc-activities-popup .description {
  font-size: 30px;
  padding: 30px 0;
}

.uc-activities-popup .cards {

  margin-top: 30px;
  padding-right: 20px;
  flex-grow: 1;
  overflow-y: auto;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-auto-rows: minmax(min-content, max-content);

}
