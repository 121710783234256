.screen {
  position: absolute;
  top: 210px;
  bottom: 440px;
  left: 350px;
  width: 1260px;

  /* z-index: 1; */


  /* border: 1px solid  red; */
}

.screen .screenBG {
  position: absolute;
  background: rgba(255, 255, 0, 0.308);

  background: url('../../../images/home/screen3d.png');
  background-position: center center ;
  height: 544px;
  width: 1273px;

  overflow: hidden;
  left: 50%;
  transform: translateX(-50%) ;
  bottom: -15px;
}

.screen .quadrillage {
  pointer-events: none;
  position: absolute;
  width: 1263px;
  bottom: 110px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-image: url('../../../images/home/lignes-horiz.png');
  background-position: bottom center;
}

.screen.capability-2 .quadrillage { top:  235px; }
.screen.capability-3 .quadrillage { top: 145px; }

.capability-btn:hover, .capacity-btn:hover { opacity: 1; }


.capacity-line {
  position: absolute;
  top: 50px;
  bottom: 30px;
  width: 3px;
  display: none;
  background: rgb(245, 67, 67);
  box-shadow: 0 0 15px 4px rgba(245, 67, 67, 0.4);
}

.screen.capacity-2 .capacity-line {
  left: 470px;
  display: block;
}

.capacity-btn {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%)  translateY(-100%);
  background-image: url("../../../images/curved_capacity.png");
}


.capacity-btn .notif {
  position: absolute;
  bottom: 0;
  left: 0;
}
