.CapaBtn  {
  border-radius: 50%;
  width: 130px;
  height: 130px;
  background-color: rgb(54, 209, 248);
  background-repeat: no-repeat;
  background-position: center 20%;
  background-size: 80%;
  opacity: 0.8;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}




.CapaBtn .slots {
  width: 0;
  height: 0;

  transform-origin: top center;

  width: 0px;
  height: 0px;
}

.CapaBtn .slot {
  width: 24px;
  height: 60px;

  background: url('../../../../images/unlocks_step_dark.png') no-repeat bottom center;
  position: absolute;
  left: -12px;
  right: 0;
  transform-origin: top center;
}

.CapaBtn .slot.active {
  background-image: url('../../../../images/unlocks_step_light.png');
}