.usecase {
  position: relative;
    height: 100%;
  width: 210px;
  border-radius: 60px 0;
  /* background: linear-gradient(to bottom,rgba(255, 0, 0, 0.514), rgba(255, 255, 0, 0.459)); */
  /* background: linear-gradient(to bottom,rgba(67, 255, 255, 0.308) 0%,rgba(67, 255, 255, 0.1) 35%,rgba(67, 255, 255, 0.1) 65%,rgba(67, 255, 255, 0.308) 100%); */
}

.usecase:hover{
  cursor: pointer;
  background: linear-gradient(to bottom,rgba(67, 255, 255, 0.308) 0%,rgba(67, 255, 255, 0.1) 35%,rgba(67, 255, 255, 0.1) 65%,rgba(67, 255, 255, 0.308) 100%);
}


.usecase .title {
  position: absolute;
  bottom: 15px;
  color: var(--lightblue);
  width: 100%;
  padding: 0 5px;
  text-align: center;
  font-size: 25px;
  line-height: 25px;
}

.usecase .phases{
  position: absolute;
  bottom: 100px;

  left: 0;
  right: 0;

  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;

  pointer-events: none;

}

.phase-head {
  order: 5;
  width: 118px;
  height: 116px;
  background: url("../../../images/phase-head.png");
  transform: translateY(30px);
  background-size: 100%;
  pointer-events: none;
}

.phases.running .phase-head {
  animation: escalope 2s infinite;
  transform-origin: center center;
}


.phases.paused .phase-head { background: url("../../../images/phase-head-pause.png"); }
.phases.unstarted .phase-head { background: url("../../../images/phase-head-not-started.png"); }

.phases.running .phase-head:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../../../images/phase-head-border.png');
  animation: tournedos 5s infinite linear;
}


@keyframes escalope{
  0% { transform: translateY(30px) scale(0.90);}
  20% { transform: translateY(30px) scale(1.2);}
  100% { transform: translateY(30px) scale(0.90);}
}
@keyframes tournedos{
  from { transform: rotate(0deg);}
  to { transform: rotate(360deg);}
}

.phases.paused .phase-tail { background: #ffaa75; }
.phases.paused .phase-tail:after { border-color: #ffaa75; }

.phase-tail {
  position: relative;
  width: 2px;
  height: 65px;
  background: #43ffff;
  margin-bottom: 15px;
  transform: translateX(-50%);
}
.phase-tail:nth-child(5) { height: 100px; }

.phase-tail:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%)  translateY(0%);
  width: 15px;
  height: 15px;
  border: 2px solid #43ffff;
  border-radius: 50%;

}
.phase-tail:nth-child(2)::after {
  display: none;
}

/* .usecase .notif {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-weight: bold;
  background-image: url('../../../images/notif_rouge.png');

  transform: translateX(-50%)  translateY(80%);
}
.usecase .notif span {
  position: absolute;
  top: -20px;
  left: 100%;

  color: #fff;
  font-size: 27px;
} */

.usecase .star {
  position: absolute;
  left: 50%;
  width: 70px;
  height: 70px;
  /* background: pink; */
  background: url('../../../images/star.png') center center no-repeat;
  transform: translateX(-50%)  translateY(-100px);
  pointer-events: none;
}

.usecase .star.filled {

  background-image: url('../../../images/starlight.png');
}
.usecase .notif {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
}