.usecases-choice-popup {
  padding: 40px;
  padding-left: 50px;
  padding-bottom: 40px;
  overflow: hidden;

  display: flex;
  flex-direction: column;

}

.usecases-choice-popup .title {
  color: #FFF;
  font-size: 50px;
}
.usecases-choice-popup .unlock-alert {
  margin: 10px 0;
  font-size: 40px;
  color: var(--warning);

}

.usecases-choice-popup .cards {
  margin: 30px 0;
  flex-grow: 1;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  align-content: start;

  overflow-y: auto;

  padding: 10px;
}


.usecases-choice-popup .bottom {
  display: flex;
  justify-content: center;
}
.usecases-choice-popup .bottom .rounded-btn{
  font-size: 2em;
  padding: 0.3em 2em;
}
