.PopupsCtnr {

}


.timer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(72, 86, 112, 0.8);

  z-index: 15;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 30px;
}

.timer-overlay > div {
  margin-top: 15px;
  border: none;
  outline: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 20px;
  color: #192249;
  cursor: pointer;
  font-weight: bold;
  font-family: inherit;
  padding: 10px 20px;
  background: var(--lightblue);
}