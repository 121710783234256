.unlock-popup {
  padding: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

}


/* .unlock-popup > * { border: 1px solid  red; } */
.unlock-popup .title {

  color: var(--lightblue);
  text-transform: uppercase;
  font-size: 50px;
}

.unlock-popup .description {
  color: #fff;
  overflow-y: auto;
  /* text-transform: uppercase; */
  font-size: 25px;
  margin: 20px 0;
  padding: 10px;
  padding-left: 0;
  flex-grow: 1;

  white-space: pre-wrap;
}

.unlock-popup .unlock-btn:hover:not(:disabled) {
  cursor: pointer;
  box-shadow: 0 0 15px var(--lightblue);
}
.unlock-popup .unlock-btn:disabled { background-color: var(--disabled); }

.unlock-popup .columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 30px;
  grid-column-gap: 30px;
}

.unlock-popup .col-title {
  padding-left: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url('../../images/icon_people.png') no-repeat 20px center;
  background-size: auto 60px;
  text-align: center;
  /* border: 1px solid  red; */
  padding: 10px 30px;
  padding-left: 100px;
  border-radius: 0 50px;
}

.unlock-popup .col-title,
.unlock-popup .col .check {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 35px;
  color: #D580FF;
}

.unlock-popup .col:first-child .col-title{ background-image: url('../../images/icon_analytics.png'); }
.unlock-popup .col:first-child .col-title,
.unlock-popup .col:first-child .check { color: #F828B0; }

.unlock-popup .col:last-child .col-title{ background-image: url('../../images/icon_dt.png'); }

.unlock-popup .col:last-child .col-title,
.unlock-popup .col:last-child .check{ color: #98FC38; }


.unlock-popup .col-title:hover {
  cursor: pointer;
  background-color: var(--disabled);
  background-repeat: no-repeat;
}



.unlock-popup .col-content {
  border: 1px solid var(--lightblue);
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}




.unlock-popup .slot {
  position: relative;
  width: 100%;
  background: var(--disabled);
  font-size: 40px;
  color:var(--lightblue);
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.unlock-popup .slot:last-child { margin-bottom: 0; }

.unlock-popup .slot.bought {
  background: #181C3F;
  font-size: 20px;
  justify-content: space-between;
  padding: 0 10px;
}

.unlock-popup .slot .check { margin-right: 10px;}

.unlock-popup .slot .activate {
  margin-right: 10px;
  font-size: 15px;
}

.unlock-popup .slot .activate .rounded-btn{ padding: 0.2em 0.7em; }

.unlock-popup .slot .slot-title {
  text-align: center;
  flex-grow: 1;
}

.unlock-popup .slot .tip {
  position: absolute;
  font-size: 20px;
  color: var(--lightblue);

  bottom: calc(100% + 20px);

  background: var(--opaque-overlay);
  padding: 10px 20px;

  border: 3px solid  var(--lightblue);
}

.unlock-popup .slot .tip::after{
  content: "";

  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);

  display : inline-block;
  height : 0;
  width : 0;
  border-top : 16px solid var(--lightblue);
  border-right : 8px solid transparent;
  border-left : 8px solid transparent;
 }



.unlock-popup .bottom {

 margin-top: 40px;

 display: flex;
 justify-content: flex-end;
 align-items: center;
}

.unlock-popup  .optionnal-unlock-activities {
  color: #fff;
  font-size: 40px;
}

.unlock-popup .unlock-btn {

  border: none;
  outline: none;
  font-size: 40px;
  line-height: 40px;
  text-transform: uppercase;
  background: url('../../images/level_up.png') no-repeat 20px center;
  background-size: 20px;
  background-color: var(--lightblue);
  color: var(--text-dark);
  padding: 10px 60px;
  padding-top: 15px;
  border-radius: 999px;
}

.unlock-popup .optionnal-unlock-activities .green{ color: var(--green); }
.unlock-popup .optionnal-unlock-activities .red{ color: var(--warning); }