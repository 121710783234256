.RecruitConsultantPopup-ctnr {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(20, 49, 88, 0.8), rgba(24, 71, 113, 0.8));


}
.RecruitConsultantPopup {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 150px;
  right: 150px;
  background: linear-gradient(to top, #143158, #184771);
  border-radius: 30px;
  padding: 100px;
}



.RecruitConsultantPopup-title {
  text-align: center;
  font-weight: 400;
  color: var(--lightblue);
  font-size: 40px;
}
.RecruitConsultantPopup-cost {
  margin-top: 40px;
  color: var(--lightblue);
  text-align: center;
  font-size: 30px;
}
.RecruitConsultantPopup-cost .cost-value {
  font-size: 90px;
}
.RecruitConsultantPopup-cost .cost-value .cost-unit {
  font-size: 30px;
  margin-left: 15px;
}

.RecruitConsultantPopup .RecruitConsultantPopup-btns {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.RecruitConsultantPopup .RecruitConsultantPopup-btns .rounded-btn{
  font-size: 45px;
}


/* .RecruitConsultantPopup .RecruitConsultantPopup-btns button{
  border: 1px solid var(--lightblue);
  border-radius: 999px;
  outline: none;
  background: none;
  font-size: 40px;
  text-transform: uppercase;
  padding: 10px 60px;
  margin: 0 20px;
  color: inherit;
  cursor: pointer;
}

.RecruitConsultantPopup .RecruitConsultantPopup-btns button:hover{
  background: var(--lightblue);
  color: var(--disabled);
}
.RecruitConsultantPopup .RecruitConsultantPopup-btns button.disabled{
  background: var(--disabled);
  color: var(--text-dark);
  cursor: no-drop;
} */