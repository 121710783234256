/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 30, 2020 */



@font-face {
    font-family: 'mohavebold';
    src: url('mohave-bold-webfont.woff2') format('woff2'),
         url('mohave-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'mohavebold_italic';
    src: url('mohave-bolditalic-webfont.woff2') format('woff2'),
         url('mohave-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'mohaveitalic';
    src: url('mohave-italic-webfont.woff2') format('woff2'),
         url('mohave-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'mohavelight';
    src: url('mohave-light-webfont.woff2') format('woff2'),
         url('mohave-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'mohavelight_italic';
    src: url('mohave-lightitalic-webfont.woff2') format('woff2'),
         url('mohave-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'mohavemedium';
    src: url('mohave-medium-webfont.woff2') format('woff2'),
         url('mohave-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'mohavemedium_italic';
    src: url('mohave-mediumitalic-webfont.woff2') format('woff2'),
         url('mohave-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'mohaveregular';
    src: url('mohave-regular-webfont.woff2') format('woff2'),
         url('mohave-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}