.countdown {
  /* position: absolute;
  top: 15px;
  left: 50px; */

  position: relative;

  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 10;

}
.countdown input {
  position: absolute;
  z-index: 1;
}
.countdown svg {
  position: absolute;
  width: 100%;
  height: 100%;
  stroke: var(--text-dark);
  /* border: 1px solid green; */
}

.countdown .outer-circle{
  stroke: var(--lightblue);
  opacity: 0.3;

}
.countdown .circle{
  transform: rotateZ(-90deg);

  transform-origin: center center;
  stroke: var(--lightblue);
  transition: all 0.5s;
}
.countdown.paused .outer-circle,
.countdown.paused .circle{
  stroke: var(--warning);
}
.countdown .period{
  color: var(--lightblue);
  opacity: 0.4;
  font-size: 20px;
}
.countdown .time {
  font-size: 50px;
  margin-bottom: -5px;
  color: var(--lightblue);
}
.countdown.paused .period,
.countdown.paused .time { color: var(--warning); }

.countdown .paused::before,
.countdown .paused::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 8px;
  background:  var(--warning);
}
.countdown .paused::before {
  right: 100%;
}
.countdown .paused::after {
  left: 100%;
}
.countdown .paused {
  position: relative;
  width: 7px;
  height: 30px;
  position: absolute;
  bottom: 30px;
  transition: opacity 0.3s;

}


.countdown .game_status.error {
  border: 1px solid  red;
  color: red;
}