.ConsultantsQty,
.RecruitConsultantButton {
  position: absolute;

  /* left: calc(100% + 20px); */
  /* right: 0; */
  left: 0;

  top: 50%;

  transform: translateX(260px) translateY(-25%);
  max-width: 150px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-dark);
  align-self: center;
}

.RecruitConsultantButton:hover {
  background: none;
  color: var(--lightblue);
  cursor: pointer;
}

.RecruitConsultantButton:hover .icon{
  background: var(--text-dark);
}


.RecruitConsultantButton .icon {
  border: 1px solid var(--disabled);
  border-radius: 50%;
  min-width:  50px;
  max-width:  50px;
  min-height: 50px;
  max-height: 50px;

  margin-right: 20px;


}


.ConsultantsQty {
  background: none;
  color: var(--lightblue);
  font-size: 20px;
}

.arc-text {
  color: var(--lightblue);
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 15px;
  transform: translateY(-20px);
}
.ConsultantsQty .arc-text{
  top: 100%;
  transform: translateY(-5px);
}