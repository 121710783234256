.human-resources {
  z-index: 10;
}

.hr-title {
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  color: var(--lightblue);
  padding: 10px 0;
  margin-bottom: 10px;
  border-radius: 50px 0;

  display: block;
  width: 100%;
  border: none;
  background: none;
  outline: none;
}
button.hr-title:hover {
  background: rgba(128, 189, 209, 0.438);
  cursor: pointer;
}
.hr-subtitle{
  text-transform: none;
  color: #2A9EC5;
  margin-top: -15px;
  font-size: 20px;
}
.hr-subtitle em{
  color: var(--lightblue);
  font-style: normal;
}
