.enablers-popup {
  padding: 40px;
  padding-left: 50px;
  padding-bottom: 40px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.enablers-popup .top {
  display: flex;
}
.enablers-popup .icon {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}


.enablers-popup .title {
  color: #FFFFFD;
  text-transform: uppercase;
  font-size: 50px;
  line-height: 50px;
}
.enablers-popup .subtitle {
  color: var(--lightblue);
  font-size: 22px;

  padding-right: 60px;
}




.enablers-popup .enablers-list {
  /* border: 1px solid  red; */
  margin-top: 30px;
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}


.enablers-popup.talents_DS .top,
.enablers-popup.talents_DE .top,
.enablers-popup.talents_BS  .top{
  padding-top: 70px;
}

.enablers-popup.talents_DS .enablers-list,
.enablers-popup.talents_DE .enablers-list,
.enablers-popup.talents_BS .enablers-list {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;
  padding: 110px;
  padding-top: 50px;


}


.enablers-popup .unbought-activities {
  background: rgba(22, 44, 81, 0.637);
  border-radius: 15px;
  padding: 15px;
  display: grid;
  grid-column-gap: 15px;
  /* grid-row-gap: 20px; */
  overflow-y: auto;
  align-content: start;
}

.enablers-popup .bought-activities {
  overflow-y: auto;
  padding: 15px;
  display: grid;
  grid-column-gap: 15px;
  align-content: start;
}
