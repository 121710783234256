.KpiPopup {
  background: linear-gradient(to bottom, rgba(20, 49, 88, 0.97), rgba(37, 105, 165, 0.97));
  padding: 100px;
}

.KpiPopup .title{
  font-size: 50px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
}

.KpiPopup .description {
  font-size: 30px;
  margin-bottom: 30px;
}
.KpiPopup .progressbar {
  margin-bottom: 80px;
}


.KpiPopup .description.penalty{
  color: var(--warning);
  font-size: 40px;
}

.KpiPopup .description.reward{
  color: var(--green);
  font-size: 40px;
}