.StartScreen {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}


.StartScreen .buttons {
  display: flex;
  flex-direction: column;

  /* border: 1px solid  red; */

  text-align: center;
}

.StartScreen .link {
  text-decoration: underline;
  cursor: pointer;
  background: none;
  color: var(--lightblue);
}

.StartScreen input,
.StartScreen label {
  display: block;
  margin: 0 auto;
  font-family: inherit;
  font-size: 25px;
}
.StartScreen input {
  color: var(--text-dark);
  margin-bottom: 30px;
}

.StartScreen a.bluebutton,
.StartScreen button {

  text-decoration: none;
  border: none;
  border-radius: 99px;
  font-size: 25px;
  background: var(--lightblue);
  color: var(--text-dark);
  padding: 10px 30px;

  outline: none;

  margin: 20px 0;
}
.StartScreen a.bluebutton:hover,
.StartScreen button:hover {
  cursor: pointer;
  background:var(--text-dark);
  color: var(--lightblue);
}

.StartScreen a.bluebutton {
  margin-bottom: 60px;
  margin-top: 0;
}

.StartScreen::before {
  content: "";
  flex-grow: 1;
}



.StartScreen .mentions {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;

  flex-grow: 1;

  padding-bottom: 30px;
}

.StartScreen .mentions div{ cursor: pointer; }

.StartScreen .mentions-popup{
  padding: 70px;
  font-size: 2em;

  display: flex;
  flex-direction: column;
}

.StartScreen .mentions-popup .title { font-size: 3em; }

.StartScreen .mentions-popup .content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  justify-content: center;
  overflow-y: auto;
}

.StartScreen .mentions-popup .content.content-mentions{ justify-content: flex-start; }
.StartScreen .mentions-popup .content p:first-child { margin-bottom: 80px; }

.StartScreen .mentions-popup .content img {
  width: 150px;
  margin-left: 40px;
  vertical-align: middle;
}
.StartScreen .version {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 10px;
  font-family: sans-serif;
  color: #FFF;
  padding: 10px;

}