.usecase-popup {
  padding: 40px;
  padding-bottom: 70px;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  flex-direction: column;

  position: relative;
}

.usecase-popup .top {
  max-height: 180px;
  margin-bottom: 30px;
}
.usecase-popup .top,
.usecase-popup .bottom{
  display: flex;
  justify-content: space-between;
}
.usecase-popup .bottom { flex-grow: 1; }



.usecase-popup .phase,
.usecase-popup .potential{
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.usecase-popup .phase {
  text-align: center;
  margin-right: 30px;
}

.usecase-popup .phase .phase-title{
  text-transform: uppercase;
  color: var(--text-dark);
}
.usecase-popup .phase .phase-nb{
  color: var(--lightblue);
  font-size: 80px;
  line-height: 80px;
}

.usecase-popup .title-block {
  max-height: 100%;
  flex-grow: 1;
  min-width: 300px;
}

.usecase-popup .title-block .subtitle,
  .usecase-popup .title-block .type{
  color: var(--lightblue);
  font-size: 23px;

}
.usecase-popup .title-block .title{
  color: #FFF;
  text-transform: uppercase;
  font-size: 35px;
}

.usecase-popup .title-block .type p{ margin: 0; }

.usecase-popup .description {
  color: var(--lightblue);
  font-size: 22px;
  padding: 10px 30px;
  padding-top: 0;
  overflow-y: auto;
  /* max-height: 200px; */
}



.usecase-popup .potential .title,
.usecase-popup .duration .title {
  text-transform: uppercase;
  color: var(--text-dark);
  font-size: 20px;
}
.usecase-popup .potential .val,
.usecase-popup .duration .val {
  text-transform: uppercase;
  font-size: 80px;
  color: var(--lightblue);
  position: relative;
  text-align: right;

}
.usecase-popup .potential .val sup {
  vertical-align: top;
  color: var(--text-dark);
  font-size: 25px;
  transform: translateY(50px);
}

.usecase-popup .col-left,
.usecase-popup .col-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.usecase-popup .col-left {
  padding: 20px;
  padding-left: 0;
  padding-right: 40px;
  padding-bottom: 0;
  min-width: 450px;
}
.usecase-popup .col-right {
  flex-grow: 1;
  justify-content: center;
}


.usecase-popup .finished {
  font-size: 40px;
  color: var(--lightblue);
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.usecase-popup .talents {
  position: absolute;
  top: 250px;
  width: 350px;
  display: flex;
  flex-direction: column;
  transform: translateX(-30px);
}

.usecase-popup .talents .uc-talents-list {
  width: 250px;
  position: relative;
  margin: 0 auto;
}

.usecase-popup .talents .talent{
  position: relative;
}
.usecase-popup .talents .talent .hr-elem{ flex-grow: 1;}

.usecase-popup .feedback-zone {
  color: var(--lightblue);
  font-size: 24px;
  text-align: center;
}

.usecase-popup .feedback-zone .warning {
  color: var(--warning);
}

.usecase-popup .staff-button:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

.usecase-popup .staff-button {
  position: absolute;
  top: 50%;
  left: 0;

  transform: translateX(-100px)  translateY(-50%);
  width: 157px;
  height: 228px;
  background: url('../../images/staff_triangle.png');
  cursor: pointer;
  outline: none;
  border: none;
}
.usecase-popup .staff-button:hover {
  filter: drop-shadow(0 0 10px rgba(69, 252, 255, 0.678))
}

.usecase-popup .staff-button .staff-button-txt{
  position: absolute;
  top: 0;
  left: 0;
  transform:  translateX(50px) translateY(10px) rotate(38deg);
  text-transform: uppercase;
  font-size: 30px;
  color: var(--lightblue);
}



.usecase-popup .rounded-button p{margin: 0;}
.usecase-popup .rounded-button {
  background: var(--lightblue);
  text-transform: uppercase;
  font-size: 40px;
  line-height: 40px;
  border-radius: 999px;
  color: #3592D5;
  margin: 0 auto;
  padding: 20px 50px;
  border: none;
  outline: none;
  cursor: pointer;
}
.usecase-popup .rounded-button:hover {  box-shadow: 0 0 15px rgba(69, 252, 255, 0.664); }
.usecase-popup .rounded-button:active {  opacity: 0.8;}
.usecase-popup .rounded-button:disabled {
  color: #225E90;
  background: #2687B1;
  cursor: not-allowed;
  box-shadow: none;
}

.usecase-popup .rounded-button small{
  display: block;
  font-size: 25px;
  line-height: 25px;
}

.usecase-popup .rounded-button {
  position: relative;
}
.usecase-popup .rounded-button .notif {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
}


.usecase-popup .activities-list {
  background: #152B5A;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}


/*************************************************************************************
.uc-activity-slot
*************************************************************************************/
.usecase-popup .activities-list .uc-activity-slot {
  position: relative;
  /* width: 470px;   */
  background: #1B5B87;
  height: 80px;
  border-radius: 5px;

  padding: 20px;
  margin-bottom: 10px;
  font-size: 20px;

  display: flex;
  align-items: center;

}

.usecase-popup .activities-list .uc-activity-slot{
  display: flex;
  align-items: center;
}
.usecase-popup .activities-list .uc-activity-slot .unselected {
  position: absolute;
  font-size: 60px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%)  translateY(-50%);
  color: var(--lightblue);
}

.usecase-popup .activities-list .uc-activity-slot .categ { margin-right: 20px; }
.usecase-popup .activities-list .uc-activity-slot .categ .categ-title{ padding-left: 20px; }


.usecase-popup .activities-list .uc-activity-slot:last-child {
  margin-bottom: 0;
}

.usecase-popup .activities-list .uc-activity-slot.bought {
  background: #181C3F;
}

.usecase-popup .uc-activity-slot .uc-activity-slot-spinner {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.usecase-popup .uc-activity-slot.pending.locked{
  background: #ff96767e;
  color: var(--warning);

}
.usecase-popup .uc-activity-slot.locked .uc-activity-slot-spinner {
  width: 40px;

  height: 40px;
  background: url('../../images/sens_interdit_activated.png');
  background-size: contain;
}
