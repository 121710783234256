.activities-buttons {
  position: absolute;


  left: 50%;
  transform: translateX(-50%);

  bottom: 60px;

  display: flex;
  perspective: 500px;
  perspective-origin: center -150px;

  /* border: 1px solid  red; */

}


.activities-button.hidden {
  opacity: 0;
  pointer-events: none;
}
.activities-button {

  position: relative;
  text-align: center;
  border: 1px solid #2A9EC5;
  border-radius: 999px;

  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;

  min-width: 300px;
  max-width: 300px;

  transition: all 0.5s;
  color: #d980ff;
}
.activities-button:first-child {
  color: #ff23b8;
  transform: translateZ(-50px) rotate3d(0, 1, 0, -16deg);
}
.activities-button:last-child {
  color: #62D92B;
  transform: translateZ(-50px) rotate3d(0, 1, 0, 16deg);
}


.activities-button:hover {
  background: rgba(42, 158, 197, 0.5);
  box-shadow: inset 0 0 30px #45fcff94;
}
.activities-button:hover .highlight{
  opacity: 1;
  transition: opacity 0.5s;
}


.activities-button .line1,
.activities-button .line2 {
  /* color: #fff; */
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  white-space: nowrap;
}
.activities-button .highlight {
  opacity: 0;
  position: absolute;
  top: -240px;
  left: 50%;
  transform: translateX(-50%);

  width: 217px;
  height: 213px;

  background: url('../../../images/activity-btn-highlight.png');
}
.activities-button:first-child .highlight {
  transform: translateX(20px);
}
.activities-button:last-child .highlight {
  transform: translateX(-220px);
}


.activities-button .notif{
  position: absolute;
  top: -240px;
  right: 50px;
}
.activities-button:first-child .notif{
  right: -70px;
}
.activities-button:last-child .notif{
  top: -250px;

  right: 120px;

}
