.uc-card {
  position: relative;


  width: 100%;
  height: 258px;

  border-radius: 15px;

  margin-bottom: 10px;

  transform-style: preserve-3d;
  transition: transform 0.8s, opacity 0.3s, box-shadow 0.3s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.uc-card.flipped{ transform: rotateY(180deg); }

.uc-card.selected,
.uc-card:not(.disabled):hover {
  box-shadow: 0 0 0 4px  var(--lightblue), 0 0 20px  #45fcff8f;
  opacity: 1;
}

.uc-card.activated .front,
.uc-card.activated .back {
  background: #0A7661;
}


.uc-card .front,
.uc-card .back{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;

  background: #15274B;
  border-radius: 15px;
  backface-visibility: hidden;


  display: flex;
  flex-direction: column;
}
.uc-card .back{ transform: rotateY( 180deg ); }


.uc-card.disabled .front { cursor: no-drop; }



.uc-card .uc-card-type{
  color: var(--lightblue);
  font-size: 25px;
  margin-bottom: 5px;
}
.uc-card .uc-card-title{
  position: relative;
  color: #FFF;
  text-transform: uppercase;
  font-size: 35px;
  line-height: 35px;
  flex-grow: 1;
}

.uc-card .uc-card-description{
  font-size: 1.4em;
  line-height: 1.5em;
  margin-bottom: 10px;

  overflow-y: auto;


  flex-grow: 1;
}
.uc-card .bottom{
  display: flex;
  justify-content: space-between;
}

.uc-card .bottom .value {
  margin-top: 10px;
  max-width: none;
  width: 100%;
  text-align: center;
  line-height: 35px;
}
.uc-card .bottom .value .value-desc{
  font-size: 20px;
  line-height: 20px;
}
