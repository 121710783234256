.hr-elem {
  position: relative;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 50px 0;
  color: var(--lightblue);
  padding-bottom: 5px;
  padding-top: 5px;

  transition: transform 0.5s;

}

.hr-elem.lowering,
.hr-elem.rising { transform: scale(1.2); }

.hr-elem.lowering {color: var(--warning);}
.hr-elem.rising {color: var(--green);}


.hr-elem.ENOUGH { color: var(--green); }
.hr-elem.SOME { color: var(--warning); }
.hr-elem.NONE { color: var(--warning); }





.hr-elem.clickable:hover {
  cursor: pointer;
  background: rgba(128, 189, 209, 0.438);
}



.hr-elem-title {
  font-size: 20px;
  white-space: nowrap;
  color: var(--lightblue);
  opacity: 0.8;
  padding: 0 20px;
  text-align: center;


}
.hr-elem.ds .hr-elem-cartouche { background-image: url('../../../images/icons/data-scientists.png'); }
.hr-elem.de .hr-elem-cartouche { background-image: url('../../../images/icons/data-engineers.png'); }
.hr-elem.bs .hr-elem-cartouche { background-image: url('../../../images/icons/business-specialists.png'); }

.hr-elem.c .contractors-tooltip::before{
  content: "";
  position: absolute;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 14px 10px 0;
  border-color: transparent var(--lightblue) transparent transparent;
}

.hr-elem.c .contractors-tooltip{
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  padding: 5px;
  background: var(--disabled);
  border: 3px solid var(--lightblue);
}
.hr-elem.c .hr-elem-cartouche {
  border: none;
  background-image: url('../../../images/icons/consultants.png');
  display: flex;

}


.hr-elem.c .hr-elem-value {
  border: none;
  border: 1px solid var(--text-dark);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  line-height: 65px;
  right: 0;

}

.hr-elem-cartouche {
  position: relative;

  margin: 0 auto;
  border: 1px solid var(--text-dark);

  border-radius: 999px;

  background-repeat: no-repeat;
  background-position: 20px center;

  overflow: hidden;
  height: 75px;
  padding-left: 30px;
  padding-right: 20px;


}

.hr-elem-cartouche .hr-elem-value {

  position: absolute;
  top: 50%;
  right: 35px;
  transform: translateY(-50%);

  color: inherit;
  margin-right: 15px;
  font-size: 35px;
  font-weight: bold;
}
.hr-elem-cartouche .hr-elem-split {
  position: absolute;
  top: 0;
  right: 15px;
  transform: rotate(30deg);
  transform-origin: top right;
  border-left: 1px solid ;
  border-color: inherit;
  height: 100px;

}
.hr-elem-cartouche .hr-elem-total {
  color: var(--text-dark);
  position: absolute;
  bottom: 8px;
  right: 20px;
  font-size: 20px;
}

.hr-elem .notif {
  position: absolute;
  bottom: 0;
  left: 15px;
  /* transform: translateY(50%); */
}

.hr-elem .hr-elem-cartouche-ctnr {
  /* border: 1px solid  red; */
  position: relative;
  border-radius: 999px;
  overflow: hidden;
  margin: 0 30px;
}

.hr-elem .hr-elem-progressbar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  width: 80%;
  background: rgba(11, 227, 255, 0.315);

  transition: width 1s linear;

}
