#logo {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

#logo .logo { width: 200px; }

.pseudo {
  font-size: 30px;

  background: url('../../../images/icons/team.png') left center no-repeat;
  background-size: 25px;
  padding-left: 50px;
}


.menu-button {
  background: url('../../../images/burger.png') no-repeat center center;
  width: 100px;
  height: 100px;
  border: 1px solid #43ffff;
  border-radius: 50%;
  margin-left: 50px;
  cursor: pointer;
}

.menu-button:hover {
  box-shadow: 0 0 20px rgba(67, 255, 255, 0.616);
}