/********************************************************
  ROUNDED BTN
********************************************************/

.rounded-btn p {
  margin: 0 !important;
}

.status-pill,
.rounded-btn {
  position: relative;
  border: none;
  outline: none;

  border-radius: 999px;
  text-transform: uppercase;
  color: #192249;
  cursor: pointer;

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: inherit;
  font-size: 32px;
  padding: 3px 38px;
  padding-top: 10px;
  line-height: 1em;
  background: var(--lightblue);
  border: 2px solid  var(--lightblue);

  margin: 0 auto;
}


.rounded-btn:hover { box-shadow: 0 0 20px rgba(69, 252, 255, 0.5); }
.rounded-btn.disabled {
  cursor: no-drop;
  background: #4C95AC;
}
.rounded-btn.disabled:hover { box-shadow: none; }

/* .rounded-btn:active, */
.rounded-btn.void-button {
  background: none;
  color: var(--lightblue);
  border: 2px solid  var(--lightblue);
}
.rounded-btn.void-button:active {
  background: var(--lightblue);
  color: #192249;
}

.rounded-btn .tooltip{
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: calc(100% + 20px);

  background: var(--warning);
  color: #192249;

  border-radius: 5px;
  font-size: 1em;
  text-transform: none;
  padding: 0.2em 0.5em;
}
.rounded-btn .tooltip::after{
  content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: var(--warning);
	border-bottom: 0;
	margin-left: -20px;
  margin-bottom: -20px;
}


.status-pill {
  border: 2px solid #44e97e;
  color: #44e97e;

  border: 2px solid var(--lightblue);
  color: var(--lightblue);


  border: 2px solid var(--green);
  color: var(--green);
  background: none;
  padding-left: 60px;
}


.status-pill.locked {
  color: var(--warning);
  border-color: var(--warning);
  background: #ff6e422f;
}

/* .status-pill.locked::before, */
.status-pill.completed::before{
  content: "✓";
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: normal;

  width: 15px;
  height: 15px;
  line-height: 15px;
}

/* le petit sens interdit */
.status-pill.locked::before {
  content: "-";
  background: var(--warning);

  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);

  color: #FFF;
  width:  25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  line-height: 0;
}