.kpis {
  text-align: center;
  color: #46feff;
  flex-grow: 1;
  /* z-index: 10; */
  padding: 0 5px;
}
.kpi{
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  padding: 20px 40px;
  padding-top: 10px;
  padding-bottom: 30px;

  border-radius: 50px 0;
  transition: transform 0.4s;
}

.kpi.positive, .kpi.negative{
  transform: scale(1.2);
}

.kpi:hover {
  background: rgba(128, 189, 209, 0.438);
  cursor: pointer;
}

.kpi .title{
  font-size: 20px;
  text-align: right;
  text-indent: 50px;
}

.kpi .notif {
  position: absolute;
  bottom: 10px;
  left: 0px;
  transform:  translateY(50%);
}