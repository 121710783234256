.OfflinePopup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 999;

  background: var(--opaque-overlay);

  display: flex;
  justify-content: center;
  align-items: center;
}
.OfflinePopup {
  padding: 40px;
  background: var(--disabled);
  color: var(--lightblue);
}