.money {
  text-align: center;
  z-index: 10;

  transition: transform 0.3s;
}

.money .title{
  color: var(--lightblue);
  font-size: 40px;
  line-height: 40px;
}
.money .subtitle{
  color: var(--lightblue);
  opacity: 0.5;
  font-size: 20px;
  text-transform: uppercase;
}
.money .val {
  color: #fff;
  font-size: 75px;
  line-height: 60px;
  margin-top: 10px;
  transition: color .5s;


}

.money.positive, .money.negative { transform: scale(2); }
.money.positive .val { color: var(--green); }
.money.negative .val { color: var(--warning); }

.money .unit {
  color: var(--lightblue);
  font-size: 30px;
}


.money .money-jauge {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;

  align-items: flex-end;

}
.money .money-jauge .barre{
  position: absolute;
  left: 0;
  top: 0;

  width: 2px;
  background: var(--lightblue);
}
