.PeriodOverlayPopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: var(--opaque-overlay);

  z-index: 15;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0 15%;

  font-size: 30px;
}
.PeriodOverlayPopup .level-jauge {
  height: 250px;
}


.PeriodOverlayPopup hr {
  border: 2px solid  var(--lightblue);
}
.PeriodOverlayPopup span {
  padding: 15px;
  overflow-y: auto;
  max-height: 100%;
}


.PeriodOverlayPopup .two-cols {
  max-height: 60%;
  display: flex;
  align-items: center;
}


.PeriodOverlayPopup .dark-bg {
  background: rgba(0, 0, 0, 0.3);
}

.PeriodOverlayPopup button {
  margin-top: 15px;
  border: none;
  outline: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 20px;
  color: #192249;
  cursor: pointer;
  font-weight: bold;
  font-family: inherit;
  padding: 10px 20px;
  background: var(--lightblue);
}
