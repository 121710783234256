.feedback-overlay {
  z-index: 14;
  display: flex;
  background: rgba(20, 24, 31, 0.9);
}

.feedback-popup {
  position: absolute;
  left: 300px;
  right: 300px;
  top: 50%;
  transform:  translateY(-50%);
  max-height: 600px;
  background: #182245;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.feedback-popup .feedback-popup-title{
  color: var(--lightblue);
  font-size: 30px;
  margin: 10px 0;
}

.feedback-popup .feedback-popup-content {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.feedback-popup .left{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.feedback-popup .feedback-img {
  width: 300px;
  height: 100%;
  margin-right: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.feedback-popup .right{
  position: relative;
  display: flex;
  flex-direction: column;

  flex-grow: 1;
}

.feedback-popup .content {
  color: #FFF;
  font-size: 24px;
  overflow-y: auto;
  overflow-y: auto;
  margin-bottom: 20px;
}

.feedback-popup-btn-ctnr { text-align: right; }

.feedback-popup .feedback-popup-btn{
  position: relative;
  border: none;
  outline: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 30px;
  cursor: pointer;
  font-weight: bold;
  font-family: inherit;
  padding: 8px 25px;
  background: var(--lightblue);
  overflow: hidden;

  border: 3px solid  var(--lightblue);
}

.feedback-popup .feedback-popup-btn:hover { opacity: 0.7; }
.feedback-popup .feedback-popup-btn:disabled{
  background: transparent;
  color: var(--lightblue);
  cursor: auto;
  opacity: 1;
}


.feedback-popup .feedback-popup-btn:active { opacity: 1; }

/* NOTE : obligé de hack un peu les z index, car si je mets tt le temps -1,
  parfois on le voit et parfois on le voit pas (la progressbar) */
.feedback-popup .feedback-popup-btn:not([disabled]) .btn-timer{ z-index: -1; }
.feedback-popup .feedback-popup-btn .btn-timer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  /* z-index: -1; */
  transition: width 3s linear;
  background: var(--lightblue);
  opacity: .5;
}

.highlighted_frame {
  position: absolute;
  z-index: 999;
  animation: borderblink 0.8s infinite alternate;
}


@keyframes borderblink {
  0% {box-shadow:  0 0 0 5px   var(--lightblue);}
  100% {box-shadow: 0 0 0 5px transparent;}
}
