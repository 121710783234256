
/***************************/
/* USE CASE ACTIVITY CARD  */
/***************************/

.uc-activity-card {
  position: relative;
  width: 100%;
  height: 241px;

  transition: transform 0.8s;
  transform-style: preserve-3d;
  /* overflow: visible; */
}

.uc-activity-card.flipped{
  transform: rotateY(180deg);
}


.uc-activity-card .front,
.uc-activity-card .back {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #15274B;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  backface-visibility: hidden;
}

.uc-activity-card .front { border-left: 6px solid; }


.uc-activity-card .back {
  background: #ABFFFF;
  transform: rotateY( 180deg );
  justify-content: space-evenly;
}

.uc-activity-card .close,
.uc-activity-card .info {
  position: absolute;
  width: 40px;
  height: 40px;

  background: none;


  border: 1px solid  var(--lightblue);
  color: var(--lightblue);
  opacity: 0.7;


  font-size: 22px;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.uc-activity-card .info:hover {
  opacity: 1;
  background: rgba(69, 252, 255, 0.6);
}

.uc-activity-card .close {
  opacity: 1;
  border: 1px solid  #192249;
  color: #192249;
}

.uc-activity-card .title{
  color: #FFF;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 24px;
  padding-left: 60px;
}
.uc-activity-card .categ .categ-title{ padding-left: 20px; }

.uc-activity-card .bottom{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.uc-activity-card .bottom .value { flex-grow: 1; }





.uc-activity-card.prebought button{ background: #ff6e42; }
.uc-activity-card.prebought button:hover{ box-shadow: 0 0 20px rgba(255, 110, 66, 0.5);}
.uc-activity-card.prebought .bottom .value{ color: #ff6e42; }

.uc-activity-card .bottom .cost,
.uc-activity-card .bottom .duration{ max-width: 30%; }

.uc-activity-card .back .title{
  padding: 0;
  padding-right: 50px;
  color: #192249;
  max-height: 50%;
}

.uc-activity-card .back .description{
  padding: 5px 0;
  padding-right: 10px;

  font-size: 22px;
  flex-grow: 1;
  max-height: 50%;
  color: #192249;
  overflow-y: auto;
}

/*** FAILED ***/
/* .uc-activity-card.failed .front{ background: rgba(255, 110, 66, 0.5); } */

/* .uc-activity-card.failed .info {
  background: rgba(255, 110, 66, 0.849);
  color: #FFF;
  border: none;
} */

.uc-activity-card.failed .status {
  color: #ffa285;
  margin-left: 40px;
  padding: 5px 10px;
  border: 1px solid #ee5223;
  border-radius: 10px;
  text-align: center;
}


/*** PENDING ***/
.uc-activity-card.pending .bottom {
  position: relative;
  display: flex;
  flex-direction: column;

}
.uc-activity-card.pending .bottom .status{
  width: 100%;
  margin-top: 10px;
  text-transform: uppercase;
  color: var(--lightblue);
  text-align: center;
}
.uc-activity-card.pending .bottom .status.locked{ color: var(--warning); }

/*** SUCCESS ***/

.uc-activity-card.bought .front{
  background: #0A7661;
}
.uc-activity-card.bought .info {
  background: #32DD9B;
  color: #192249;
  border: none;
}
.uc-activity-card.bought .bottom{
  text-align: center;

}

.uc-activity-card .notif {
  position: absolute;
  bottom: 10px;
  left: 10px;
}