.enabler {
  position: relative;
  width: 100%;
  height: 265px;
  transform-style: preserve-3d;
  border-radius: 15px;

  transition: transform 0.8s;
}
.enabler.flipped{ transform: rotateY(180deg); }


.enabler .enabler-front,
.enabler .enabler-back { background: #142B4D; }




.enabler.bought .enabler-front,
.enabler.bought .enabler-back { background: #0A7661; }

.enabler.tuto_locked { color: var(--warning); }
.enabler.tuto_locked .enabler-front, .enabler.tuto_locked .enabler-back {
  background: #ff6e4234;
  background: #46475F;
}


.enabler.tuto_locked .enabler-image {
  filter: sepia(1) brightness(0.6)  hue-rotate(335deg) saturate(3);
}




.enabler .debug_openpopup {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;

  z-index: 3;
  background: rgba(255, 0, 0, 0.0);
  border-radius: 50%;
  transform: translateX(50%)  translateY(50%);
}

.enabler .enabler-front,
.enabler .enabler-back{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 15px;

  overflow: hidden;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.enabler .enabler-back{
  transform: rotateY( 180deg );
  pointer-events: none;
}
.enabler.flipped .enabler-back{ pointer-events: auto; }
.enabler.flipped .enabler-front{ pointer-events: none; }

.enabler .enabler-title{
  font-size: 30px;
  line-height: 1.1em;

  flex-grow: 1;
  /* border: 1px solid lime; */
  display: flex;
  align-items: center;
}

.enabler .enabler-title .enabler-image {
  float: left;
  margin-right: 20px;
  min-width: 3em;
  max-width: 3em;
  min-height: 3em;
  max-height: 3em;
  background-repeat: no-repeat;
  background-size: contain;
}

.enabler-middle {
  font-size: 30px;
  text-align: center;
}

.enabler-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;

}

.enabler .enabler-feedback {
  font-size: 20px;
  padding-left: 60px;
  background: url(../../../images/cadenas.png) left center no-repeat;
  background-size: 50px ;
  margin-bottom: 10px;
}

.enabler .description{
  font-size: 1.4em;
  overflow-y: auto;
  margin-bottom: 10px;
}
.enabler .enabler-values{
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  /* border: 1px solid  red; */
  /* margin: 0 40px; */
}
.enabler .enabler-values > *{
  /* min-width: 50%;
  max-width: 50%; */
  font-size: 50px;
  text-align: center;
}




.enabler.unclaimed .enabler-btn:hover { background: var(--text-dark); }

.enabler .enabler-btn{
  font-size: 2em;
  padding: 1.2em;
  padding-top: 0.3em;
  padding-bottom: 0.1em;
  line-height: 1em;
}


.enabler .notif {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* TODO */
.enabler.locked .enabler-title{ color: #CCC; }
.enabler.locked .enabler-values .value{ color: #CCC; }

.enabler .infinite {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-30%)  translateY(30%);
  width: 70px;
  height: 70px;
  background: url('../../../images/replay.png') #ffffbf no-repeat 27px 11px;


  border-radius: 50% 50% 50% 15px;
}