.categ {
  display: flex;
  border-radius: 50px 0;
}


.categ.data {
  color: yellow;
}


.categ.data { color: #FF7B9C; }
.categ.business_impact { color: #FA89FF; }
.categ.algorithm { color: #35D7FF; }
.categ.output_ui { color: #E8DB73; }
.categ.tech_stability { color: #ABEA65; }

.categ.selectable:hover,
.categ.selected {
  cursor: pointer;
  background: rgba(128, 189, 209, 0.438);
}

.categ .icon{
  min-width: 40px;
  max-width: 40px;
  min-height: 50px;
  max-height: 50px;

  background-size: 35px ;
  height: auto;

  width: 100%;

  flex-grow: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;


}
.categ .categ-title{
  flex-grow: 1;

  text-transform: uppercase;
  text-align: center;
  font-size: 20px;

  display: flex;
  align-items: center;

}

.uc-activities-popup .top .categs .categ {
  flex-direction: column-reverse;

  align-items: center;
  margin: 0 10px;
  min-width: 80px;

  padding: 20px 40px;
}
.categ .count{
  font-size: 20px;
}

.categ .count span{
  font-size: 30px;
  color: #fff;

}